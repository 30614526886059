import React from 'react';

import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => (
  <div className={styles.container}>
    <h2>Further Privacy Policy</h2>

    <blockquote>Effective Date: December 17th 2024</blockquote>

    <br />

    <h3>INTRODUCTION</h3>

    <p>
      CEED LTD (referred to as &ldquo;pi-top&rdquo;) has created Further, an
      online hub for project-based learning, to help you get the best out of
      each of your students. To achieve this goal, it&rsquo;s essential that
      Further is a safe place for students to document their learning, and that
      you have complete control over how your personal information and your
      students&rsquo; personal information is shared.
    </p>

    <p>
      Protecting your privacy is fundamental to our mission and business. The
      following summarize our promises to you.
    </p>

    <ul>
      <li>We never sell your data or student data.</li>
      <li>
        We never advertise to student accounts in Further, and we don&#39;t show
        third party ads to other users.
      </li>
      <li>Student work is private to the Further Class by default.</li>
      <li>
        We use the latest security industry best practices to protect you.
      </li>
      <li>
        We are transparent about our practices and will notify you about any
        significant changes.
      </li>
      <li>
        We are compliant with FERPA, COPPA, SOPPA, GDPR, and the Australian
        Privacy Act.
      </li>
    </ul>

    <p>
      This Privacy Policy governs the use of data collected by our website at
      further.pi-top.com (&ldquo;the Further Service&rdquo;, &ldquo;the
      Service&rdquo; or &ldquo;Further&rdquo;). This includes personally
      identifiable information that we collect when you create an account
      (&ldquo;Account Information&rdquo;), Classes and Challenges created in
      Further by Teachers, content added to Challenges (&ldquo;Challenge
      Response Content&rdquo;), and Comments posted in Further. Any data
      collected by Further that can be linked back to an individual student is
      considered &ldquo;Student Data&rdquo;. The term &ldquo;Teachers&rdquo; may
      include teachers, tutors or others who manage Classes in Further.
    </p>

    <p>
      By using Further, you agree to this Privacy Policy. If you don&rsquo;t
      agree, please don&rsquo;t use Further. You can contact us anytime with
      questions about this policy at support@pi-top.com.
    </p>

    <h3>WHO DOES FURTHER COLLECT INFORMATION FROM?</h3>

    <p>
      We will collect data from Teachers and students when they create accounts
      on Further. We may also collect data from visitors to marketing web pages
      relating to Further on our website at <u>pi-top.com</u>
    </p>

    <h3>WHAT INFORMATION DOES FURTHER COLLECT?</h3>

    <p>
      <strong>Account Information: </strong>
      When Teachers create an account on Further, we collect your full name,
      profile picture, email address and password. Where we create an account
      for you and invite you to the account, you may enter the same information
      in your Account Settings.
    </p>

    <p>
      The only personally identifiable information collected by Further about
      students is their full name, profile picture and email address. This may
      be entered or updated by a Teacher or the student or populated from the
      student&rsquo;s account with a third party sign-in service, such as their
      Google account.
    </p>

    <p>
      <strong>Challenges: </strong>
      Teachers may use Further to create Challenges to use with their students.
      Challenges may include text, video or voice instructions for how to
      complete the Challenge, an example of a correct response or a template for
      students to edit.
    </p>

    <p>
      <strong>Challenge Response Content: </strong>
      Further also collects content that is added to a Challenge [by students].
      This content may be text, photos, drawings, audio or video files,
      hyperlinks, and other ways of documenting student learning. We may add new
      types of information that can be uploaded to a Challenge, and these are
      all covered by this Policy. We may also collect Comments on aspects of a
      Challenge. Challenge Response Content that is uploaded by a student or
      Teacher may be considered a student education record as defined by FERPA.
    </p>

    <p>
      <strong>Communications: </strong>
      Further collects any information you send to us directly, such as in email
      communications.
    </p>

    <p>
      <strong>
        Information from your Google Account or other Third-Party Sign-in
        Service:{' '}
      </strong>
      Further allows Teachers and students (after being invited by a Teacher) to
      sign up for and log into our service using a Google Classroom or Clever
      Account. When we create a Further account for a Teacher using one of these
      Third-Party Services, we will collect and use your full name, email
      address, Google or other service ID and the URL of your profile picture
      provided by these services. We may also collect information about your
      Google Classroom classes and class members. When we create an account for
      a student using one of these services, we only collect the student&rsquo;s
      full name and email address.
    </p>

    <p>
      <strong>Other information</strong>
      <strong>: </strong>
      When you use Further, we receive log data such as your IP address, browser
      type, operating system, device information, and your mobile carrier. In
      addition, we may receive or collect additional information such as the
      referring web page, referring search terms, and pages visited.
    </p>

    <h3>HOW DOES pi-top USE THIS INFORMATION?</h3>

    <p>
      We only use this information to provide our services to you. For example,
      we use this information to:
    </p>

    <ul>
      <li>
        Allow you to access and use our service by verifying your identity and
        storing your Classes, Challenges, Challenge Response Content, and
        Comments.
      </li>
      <li>Provide you with comprehensive customer support.</li>
      <li>
        Notify you about activity on and updates to your account or your
        students&rsquo; accounts (if you&rsquo;ve indicated in your account
        settings that you&rsquo;d like to receive notifications).
      </li>
      <li>
        Research, understand, and analyze trends of users to improve and develop
        new features for our products.
      </li>
      <li>
        Promote enhancements to Further relevant for Teachers and schools.
      </li>
      <li>
        Investigate, prevent, and detect activities on our service that we
        believe may violate the law or applicable regulations. We may, at the
        request of a school, investigate accounts to determine whether they
        comply with school policies.
      </li>
    </ul>

    <p>
      You can withdraw consent for the collection of your personal information
      at any time, and also opt-out of marketing communications from us.
    </p>

    <h3>DOES pi-top ALLOW ADVERTISING OR SHARE DATA FOR ADVERTISING?</h3>

    <p>
      We may promote products from pi-top and our business partners within
      Further. We never show these promotions to student accounts.
    </p>

    <p>
      We never display third-party ads in Further, and we do not share data for
      advertising or marketing purposes, or allow data collection by third-party
      advertisers or data brokers.
    </p>

    <h3>
      IN WHAT LIMITED CIRCUMSTANCES MAY pi-top NEED TO SHARE MY INFORMATION?
    </h3>

    <p>
      We do not sell or share any data you provide with third parties except in
      the limited circumstances detailed below:
    </p>

    <ul>
      <li>
        We use a small number of third-party services in order to operate and
        improve Further &ndash; for example a data center operator that manages
        our servers or a notification service that helps us send you messages
        about your account. These services need access to your personally
        identifiable information in order to work (i.e. your email address is
        required to send you email), but are contractually prohibited from using
        that information for any other purpose other than to provide the Further
        service. In cases of onward transfer to third parties of data of EU
        individuals received pursuant to the EU-US Data Privacy Framework and
        the UK and Swiss-US DPF extensions , pi-top is potentially liable. A
        list of the third-party services we currently use that obtain personally
        identifiable information can be found at the end of this section. When
        these third-party services transfer the personal information (Personal
        Data, as that term is defined in GDPR) of EU residents, these services
        are processors and are contractually bound to also comply with GDPR to
        protect your data privacy and security.
      </li>
      <li>
        For any of the purposes and uses of information described in this
        Privacy Policy, we may share your information with pi-top’s affiliate
        companies and subsidiaries in furtherance of our legitimate interests.
      </li>
      <li>
        pi-top may share your information with our business partners with whom
        you interact to facilitate your relationship with pi-top and those
        business partners for any of the purposes described in our or those
        business partners’ privacy statements.
      </li>
      <li>
        pi-top may disclose your information to a third party to comply with
        applicable laws or regulations, or a valid legal request - including to
        meet national security or law enforcement requirements. If we are going
        to release your data, we will do our best to provide you with notice in
        advance by email, unless we are prohibited from doing so by law.
      </li>
      <li>
        pi-top may disclose student Account Information and Challenge Response
        Content to the student&rsquo;s school district upon request, as required
        by FERPA.
      </li>
      <li>
        We may disclose or transfer your Account Information and Challenge
        Response Content in connection with the sale, merger, bankruptcy, sale
        of assets or reorganization of our company. We will notify you if a
        different company will receive your information. The promises in this
        Privacy Policy will apply to your data as transferred to the new entity.
      </li>
    </ul>

    <p>Third-party services we currently use are:</p>

    <ul>
      <li>
        Google, Inc
        <br />
        We use Google for website hosting, infrastructure provision and
        analytics. Their privacy policy can be found at{' '}
        <u>http://www.google.com/intl/en/policies/privacy/</u>.
      </li>
      <li>
        Hubspot, Inc
        <br />
        We use Hubspot to manage sales contacts and email subscriber lists and
        to send emails to our subscribers. Their privacy policy can be found at{' '}
        <u>https://legal.hubspot.com/privacy-policy</u>.
      </li>
      <li>
        Brevo
        <br />
        We use Brevo to manage email subscriber lists and to send emails to our
        subscribers. Their privacy policy can be found at{' '}
        <u>https://www.brevo.com/legal/privacypolicy</u>.
      </li>
      <li>
        Intercom, Inc
        <br />
        We use Intercom for customer messaging and analytics. Their privacy
        policy can be found at{' '}
        <u>https://www.intercom.com/terms-and-policies#privacy</u>
      </li>
      <li>
        Sinch (Mailgun)
        <br />
        We use Mailgun to deliver emails to users such as email verification.
        Their privacy policy can be found at{' '}
        <u>https://www.mailgun.com/privacy-policy/</u>
      </li>
      <li>
        Cloudflare, Inc
        <br />
        We use Cloudflare for website infrastructure. Their privacy policy can
        be found at <u>https://www.cloudflare.com/en-gb/privacypolicy/</u>
      </li>
    </ul>

    <h3>DO YOU COLLECT DATA ABOUT [BLANK]?</h3>

    <p>
      We&rsquo;ve listed all the data we collect through Further in the section
      above titled What Information Does Further Collect. We intentionally limit
      our data collection to only what we need to provide the Further service
      for you.
    </p>

    <p>
      However, sometimes we get asked if we collect data about some other topic
      or area that&rsquo;s of particular interest to a school. To be clear, we
      do not collect biometric, behavioral, free or reduced lunch eligibility,
      health, or financial data.
    </p>

    <h3>DO YOU WORK WITH THIRD-PARTY ANALYTICS SERVICES?</h3>

    <p>
      We are constantly improving Further, and we use aggregate data about how
      Further is used -- for example what buttons you click on or what pages you
      visit -- to inform those decisions.
    </p>

    <p>
      To help us analyze this data, we use a small number of third-party
      services (such as Google Analytics and Hubspot analytics). These services
      are contractually obligated only to use data about your usage of Further
      to provide analytics services to us and are prohibited from sharing it or
      using it for other purposes. If you do not wish to participate in Google
      Analytics, you may download the Google Analytics opt-out browser add-on.
    </p>

    <h3>HOW DO YOU USE COOKIES?</h3>

    <p>
      Cookies are small text files that we transfer to your web browser. Cookies
      allow us to store information about your account, and to collect
      information such as your browser type, time spent on Further, and language
      preferences. We use the information to keep you logged in to Further, for
      security purposes, to facilitate navigation, to display information more
      effectively, and to customize your experience. We also use cookies to
      gather statistical information about the usage of Further in order to
      continually improve its design and functionality and to assist us with
      resolving questions about it. We may use the information we collect to
      promote Further to relevant Teachers and schools.
    </p>

    <p>
      You can choose to remove or disable cookies via your browser settings.
      Please be aware that Further will not work properly if you disable or
      decline cookies.
    </p>

    <h3>HOW DO YOU USE PIXEL TAGS?</h3>

    <p>
      We may insert pixel tags (also known as web beacons and clear GIFs) within
      Further and HTML-formatted email messages. We use pixel tags to understand
      how you use Further and to gather statistical information about the usage
      of Further which we use to improve its design and functionality. We may
      also use the information we collect to promote Further to relevant
      Teachers and schools.
    </p>

    <h3>
      HOW CAN USERS SHARE STUDENT INFORMATION AND CHALLENGE RESPONSE CONTENT?
    </h3>

    <p>
      Further is designed to provide safe sharing within Classes. Challenges
      created by Teachers are private to their account. Challenge Response
      Content and Comments can only be viewed by the Teacher and any students in
      a Class to which the Teacher has assigned the Challenge. No student
      responses to Challenges are ever shared with anyone other than the
      Teacher. However, it&rsquo;s worth noting that we cannot prevent all forms
      of sharing (e.g. by taking a screenshot and emailing it), so it&rsquo;s
      important that Teachers explain to their students the potential risks of
      sharing their content.
    </p>

    <h3>HOW DOES FURTHER HANDLE ABANDONED ACCOUNTS?</h3>

    <p>
      Further will delete an account and all content associated with the account
      if the account has not been accessed for more than 2 years. Prior to
      deleting an abandoned account, Further will notify the Teacher or school
      associated with the account by email and provide an opportunity to
      download an archive copy of the class journal.
    </p>

    <h3>
      HOW TO VIEW, CORRECT, EDIT, PORT, OR UPDATE YOUR PERSONAL INFORMATION
    </h3>

    <p>
      You have the right to access, correct, or delete any of your personal
      information collected by Further. If you are a Teacher, you can update the
      information associated with your Further account directly by logging into
      your Further account and viewing the Account Settings tab on your profile.
      If you are a parent and want to correct, edit, download, or update
      information about a student, please work directly with your Teacher or
      school, or you can contact us at support@pi-top.com.
    </p>

    <h3>HOW TO DELETE YOUR FURTHER ACCOUNT</h3>

    <p>
      If you would like to delete your Further account or any content submitted
      to Further, please send an email to support@pi-top.com. If you request
      that your account or any content submitted to Further be deleted, pi-top
      may still retain information for up to 60 days to provide customer support
      and prevent accidental deletion.
    </p>

    <p>
      If you are a Teacher or school administrator within the US, please be
      aware that FERPA requires us to retain student education records once a
      valid request to inspect those records has been made.
    </p>

    <h3>HOW DOES FURTHER KEEP YOUR DATA SAFE?</h3>

    <p>
      pi-top takes the protection of your security and privacy seriously. To
      safeguard your data, we implement a range of security measures, including:
    </p>

    <ul>
      <li>
        <strong>Encryption:</strong> We apply industry-standard encryption
        (minimum AES-256 for data at rest and TLS 1.2+ for data in transit) to
        ensure that all personal data is protected.
      </li>
      <li>
        <strong>Access Controls:</strong> We implement robust access control
        mechanisms to ensure that only authorized personnel can access sensitive
        data. Access is granted on a need-to-know basis, and all access is
        traceable to maintain accountability.
      </li>
      <li>
        <strong>Audit Logs:</strong> We maintain detailed audit logs to track
        and monitor all access to user data, ensuring transparency and
        accountability in data handling.
      </li>
      <li>
        <strong>Third-Party Audits:</strong> We conduct third-party security
        audits at least annually to independently verify that our security
        practices meet or exceed industry standards.
      </li>
      <li>
        <strong>Data Centers and Technologies:</strong> We use industry-leading
        data centers and secure, up-to-date technologies to store and process
        your data safely.
      </li>
      <li>
        <strong>Password Security:</strong> We enforce strong password policies
        and store passwords with additional encryption to further protect your
        data.
      </li>
    </ul>

    <p>
      In the event of a security breach, we will notify affected users or
      schools within the amount of time required by law and our agreements, at
      minimum 72 hours for our users and customers and 30 days for parents.
      Schools will be informed directly via the contact information provided,
      while parents will receive notifications through the appropriate channels
      designated by the schools. This ensures that all relevant parties can take
      the necessary steps to protect their data.
    </p>

    <h3>PARENTAL CONSENT</h3>

    <p>
      We require that Teachers or schools get parental consent before using
      Further with children who are under the age when they can grant consent on
      their own. This age may vary based on where you live. For example, in the
      US that age is younger than 13. You should check your local laws to
      determine the relevant age in your country. Parents or guardians can
      withdraw consent for the collection of their child&rsquo;s information at
      any time. If you are aware Further is collecting information from a
      student without parental consent, please contact us immediately at
      support@pi-top.com and we will delete the data.
    </p>

    <p>
      There are a number of ways in which Teachers or schools can obtain
      parental consent:
    </p>

    <ul>
      <li>
        Get consent as part of a school-wide technology consent process you may
        already have in place.
      </li>
      <li>
        For Teachers in the United States, agree to act as the parent&rsquo;s
        agent, and provide consent on their behalf to use Further solely in the
        educational context as provided by the FTC.
      </li>
    </ul>

    <h3>PARENTAL ACCESS AND RIGHTS (FERPA, SOPPA, etc.)</h3>

    <p>
      Further respects the rights of parents or legal guardians to access and
      manage their child&rsquo;s educational data, as outlined by applicable
      laws, including FERPA and SOPPA. Under these laws, parents or guardians
      have the right to review, correct, and request the deletion of their
      child&rsquo;s data. To exercise these rights, parents or guardians can
      contact us at support@pi-top.com. We ensure that such requests are
      processed in accordance with FERPA, SOPPA, and other applicable privacy
      laws.
    </p>

    <p>
      Data collected by Further may include personally identifiable information
      from education records that are subject to the Family Educational Rights
      and Privacy Act, &ldquo;FERPA&rdquo;, (&quot;FERPA Records&quot;). To the
      extent that Student Data includes FERPA Records, you designate pi-top as a
      &quot;School Official&quot; (as that term is used in FERPA and its
      implementing regulations) under the direct control of the school with
      regard to the use and maintenance of the FERPA Records and pi-top agrees
      to comply with FERPA.
    </p>

    <h3>AGREEMENTS WITH EDUCATIONAL AND INSTITUTIONAL ENTITIES</h3>

    <p>
      Agreements between pi-top and educational or institutional entities,
      including school districts and other organizations, govern the collection,
      processing, and handling of Student Data through the Further platform.
      These agreements apply in addition to this Privacy Policy and applicable
      laws, specifically in relation to the activities and operations of the
      respective entities.
    </p>

    <h3>GDPR</h3>

    <p>
      Further complies with the European Union General Data Protection
      Regulation (&ldquo;GDPR&rdquo;) and makes it easy for EU individuals to
      exercise their rights described in that regulation. The purposes for which
      Further collects your information, the specific types of information, and
      our practices and policies regarding the processing of your information
      are described in this Privacy Policy. If you have specific questions about
      how Further is compliant with GDPR, please contact us at
      support@pi-top.com.
    </p>

    <h3>HOW DOES FURTHER HANDLE DATA FROM INTERNATIONAL VISITORS?</h3>

    <p>
      The Further Service is hosted and operated primarily in the United States
      and is subject to United States law. All databases holding user data are
      hosted exclusively in the US. There are application servers and other
      infrastructure in other regions. By using this Service, you consent to the
      transfer to and processing of your personal information in the United
      States.
    </p>

    <p>
      Further complies with the EU-U.S. Data Privacy Framework (“EU-U.S. DPF”),
      the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. Data Privacy
      Framework (“Swiss-U.S. DPF”) as set forth by the U.S. Department of
      Commerce (collectively “the Data Privacy Framework”) regarding the
      collection, use, and retention of personal information from the European
      Union, the United Kingdom, and Switzerland transferred to the United
      States. Further has certified that it adheres to the DPF Principles with
      respect to such data. If there is any conflict between the policies in
      this privacy policy and data subject rights under the DPF , the DPF
      Principles shall govern. To learn more about the Data Privacy Framework,
      please visit <u>https://www.dataprivacyframework.gov/</u>.
    </p>

    <p>
      With respect to personal data received or transferred pursuant to the DPF,
      Further is subject to the regulatory enforcement powers of the U.S.
      Federal Trade Commission.
    </p>

    <p>
      In certain situations, we may be required to disclose personal data in
      response to lawful requests by public authorities, including to meet
      national security or law enforcement requirements.
    </p>

    <p>
      pi-top&rsquo;s accountability for personal data that it receives in the
      United States under the DPF and subsequently transfers to a third party is
      described in the DPF Principles. Pursuant to the DPF, pi-top remains
      liable for the transfer of personal data to third parties acting as our
      agents unless we can prove we were not a party to the events giving rise
      to the damages.
    </p>

    <p>
      In compliance with the DPF Principles, pi-top commits to resolve
      complaints about your privacy and our collection or use of your personal
      information transferred to the United States pursuant to DPF. European
      Union, UK and Swiss individuals with DPF inquiries or complaints should
      first contact us by email at support@pi-top.com.
    </p>

    <h3>RIGHTS OF CALIFORNIA RESIDENTS</h3>

    <p>
      pi-top complies with California&rsquo;s &ldquo;Shine the Light&rdquo; law.
      California residents can request a list of what personal information about
      them we&rsquo;ve disclosed to third parties in the preceding calendar
      year, as well as the names and addresses of those third parties. You may
      make such a request only once per year, and you don&rsquo;t have to pay
      for it. To make your request, send an email to support@pi-top.com or send
      a written request to CEED Inc, 9600 Great Hills Trail, Suite 150W, Austin,
      Texas, 78759.
    </p>

    <h3>CHANGES TO OUR PRIVACY POLICY</h3>

    <p>
      pi-top may from time to time make changes to this Privacy Policy to
      account for changes to our practices or applicable law. If we make changes
      to this Privacy Policy that we believe will materially affect your rights,
      we will notify you by email about these changes and post a notice to our
      service. If you continue to use our service after you receive notice of
      changes to this Privacy Policy, you will accept these changes.
    </p>

    <h3>CONTACT INFORMATION</h3>

    <p>
      If you have any questions about this Privacy Policy or feedback, please
      contact support@pi-top.com. You can reach us by mail at:
    </p>

    <p>
      CEED, Inc.
      <br />
      9600 Great Hills Trail, Suite 150W
      <br />
      Austin
      <br />
      Texas 78759
      <br />
      U.S.A.
      <br />
    </p>

    <p>
      CEED LTD
      <br />
      82 Tanner Street
      <br />
      London
      <br />
      SE1 3GN
      <br />
      United Kingdom
      <br />
    </p>
  </div>
);

export default PrivacyPolicy;
