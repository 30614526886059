import React from 'react';
import * as Sentry from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

Sentry.init({
  enabled: !!(window && window.PT_ENV && window.PT_ENV !== 'undefined'),
  environment: window.PT_ENV,
  dsn:
    'https://5e34cc642a7b3261bfcb1c38776dc091@o4506982689996800.ingest.us.sentry.io/4507016495497216',
  integrations: [
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      enableInp: true,
    }),
  ],
  attachStacktrace: true,
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/(further|backend)\.pi-top\.com/,
    /^https:\/\/(further|backend)-test\.pi-top\.com/,
    /^https:\/\/(further|backend)-staging\.pi-top\.com/,
  ],
});
